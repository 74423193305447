import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import logo from '../../images/rtfooter.png';
import appstore from '../../images/appstore.webp';
import iosstore from '../../images/ios-store.webp';
import { BsWhatsapp, BsLinkedin, BsInstagram, BsFacebook } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import SmallComp from "./SmallComp";
import { useSelector } from "react-redux";

const  Footer = () => {
  const head = ["news", "earth", "real", "sports", "worklife", ]
  const navdata = useSelector(state => state.masterdata.navdata);
  const homedata = useSelector(state => state.masterdata.homedata);
  const [show, setShow] = useState(7);
  const [buttonShow, setButtonShow] = useState(true);
  const handelViewMore = () => {
    setShow(-1);
    setButtonShow(false);
  };

  const handelViewLess = () => {
    setShow(7);
    setButtonShow(true);
  };
  const data = [
    {
        image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
        category_name: "health",
        author_name: "Sridhar",
        discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
        published_date: "20/20/2039",
    },
    {
        image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
        category_name: "travel",
        author_name: "murali",
        discription: "Early tourists choices to the sea of Maldiv…",
        published_date: "20/20/2039",
    },
    {
        image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
        category_name: "health",
        author_name: "Sridhar",
        discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
        published_date: "20/20/2039",
    },
   



]
  return (
    <>

      <div className="footerSecondContainer">
        <Container

          className="maxWidthContainerFluid paddingContainerFluid"
        >
          <Row >
            <Col lg={4} md={4}>
              <Row>
                <Col lg={12}>
                  <img
                  referrerPolicy="no-referrer"
                    src={localStorage.getItem("footer_logo")}
                    className="Red-teluguLog"
                  />
                 
                  {/* <div className="footer_descip">Red telugu is the one of best news channel and it has over the years become the most watched, credible and respected news network in India</div> */}
                <div className="footer-address">{localStorage.getItem("address")}</div>
                </Col>
              </Row>
              <Row>
              <Col lg={12} className="mt-2">
                  <h1 className="wearealways">We're always here to help you</h1>
                  <p className="Reachout">Reach out to us through any of these support channels</p>
                  <div className="midiacont">
                    <div style={{ display: "flex" }}>
                      <p className="massages">
                        whatsapp : {" "}
                      </p>
                      <p className="massages" style={{ marginLeft: "7px" }}>
                        {localStorage.getItem("vendor_contact")}
                      </p>
                    </div>

                    <div style={{ display: "flex" }}>
                      <p className="massages">
                        email : {" "}
                      </p>
                      <p className="massages" style={{ marginLeft: "7px" }}>
                        {localStorage.getItem("vendor_email")}
                      </p>
                    </div>

                  </div>
                </Col>
              </Row>
              {/* <Row style={{ paddingTop: "8px" }}>
                <Col lg={12}>

                  <div >
                    <h1 className="Connectwithus">Connect with us</h1>
                    <div className="sosalmidiaimg">
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="facebook"
                      >
                        <BsFacebook color="#fff" size="20" />

                      </a>

                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="instagram"
                      >
                        <BsInstagram color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="whatsapp"
                      >
                        <BsWhatsapp color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="linkedIn"
                      >
                        <BsLinkedin color="#fff" size="20" />
                      </a>
                      <a
                        href="#"
                        className="facebook"
                        target="_blank"
                        rel="noreferrer"
                        title="twitter"
                      >
                        <AiOutlineYoutube color="#fff" size="25" />
                      </a>
                    </div>
                  </div>
                </Col>
              </Row> */}
            </Col>
            <Col lg={4} md={4}>
              <Row>
                <Col lg={12}>
                  <h1 className="footerHeading">
                  TRENDING CATEGORIES
                  </h1>
                  <div className='header-below-list'>

                            <div className='dgfdh'>
                                {
                                    navdata.map(ele => {
                                        return (
                                            <div className="footer-category-list" >
                                               <NavLink className={"text-decoration-none"} to={`/Category/${ele.slug}`}>
                                                <span className='header-below-list-items'> <span className='ftdfdf'></span> {ele.name}</span>
                                                </NavLink>
                                            </div>
                                        )

                                    })
                                }
                            </div>
                            {/* <span className='header-below-list-items header-below-list-more'> more <IoCaretDownSharp  /></span> */}
                        </div>
                </Col>
              </Row>
              <Row>
                
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Row >
                <Col lg={12} sm={10} md={10} xs={10}>
                  <div className="app_downlod">
                    <p className="secondfooterHeading">
                    TRENDING NEWS
                    </p>
                  </div>
                  <>{
                    homedata && homedata.carouselList &&
                    Object.keys(homedata.carouselList.trending_items
                      ).length > 0 &&

                   homedata.carouselList.trending_items.slice(0,3).map(ele=>{
                    return(
                      <NavLink to={`/Detail/${ele.slug}`} className={"text-decoration-none"} >
                      <SmallComp title={"category"} image={ele.image} published_date={ele.published_date} category_name={ele.category_name} discription={ele.title}/>
                       </NavLink>
                    )
                   })
                  }
                  </>
                
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {/* <Col lg={12}>
              <div className="middle_paragraph">
                <NavLink to="/about-ourshopee" className="dddddd">
                  {" "}
                  about
                </NavLink>{" "}
                |
                <NavLink to="/sitemap" className="dddddd">
                  siteMap
                </NavLink>{" "}
                |
                <NavLink to="/customer-reviews" className="dddddd">
                  submitReview
                </NavLink>
                |
                <NavLink to="/return-policy" className="dddddd">
                  warranty
                </NavLink>
                |
                <NavLink to="/privacy-policy" className="dddddd">
                  {" "}
                  policy
                </NavLink>
                |
                <NavLink to="/terms-and-conditions" className="dddddd">
                  term
                </NavLink>
                |
                <NavLink to="/blogs" className="dddddd">
                  blog
                </NavLink>
                |
                <NavLink to="/contact-us" className="dddddd">
                  contactUs
                </NavLink>
                |
                <NavLink to="/faqs" className="dddddd">
                  faq
                </NavLink>
                |
                <NavLink to="/sell-with-us" className="dddddd">
                  sell
                </NavLink>
                |
                <NavLink to="/affiliate-program" className="dddddd">
                  {" "}
                  affialate
                </NavLink>
              </div>
            </Col> */}
          </Row>
          <Row style={{ paddingTop: "20px" }}>
            
            <Col lg={12}>
              <div className="footer_component">
                <p className="ourshop_com">Copyright ©2023 www.redtelugu.com. All rights reserved</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
