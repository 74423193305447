// App.js
import React, { lazy, Suspense, useMemo } from 'react';

const LazyItem = lazy(() => import('./Home'));

const items = Array.from({ length: 1000 }, (_, index) => `Item ${index + 1}`);

const Search = () => {
  return (
    <div>
      <h1>Lazy Loaded List</h1>
      <Suspense fallback={<div>Loading...</div>}>
        {items.map((item, index) => (
          <MemoizedItem key={index} item={item} />
        ))}
      </Suspense>
    </div>
  );
};

// MemoizedItem.js
const MemoizedItem = React.memo(({ item }) => {
  return <div>{item}</div>;
});




export default Search;