import React from "react";
import Apis  from "../../services/Apis";
const RelatedAction = () =>{
    const {getRelatedPostslist} =Apis();

     const getRelatedPostslistdata =(input_data)=>async dispatch =>{
        const response = await getRelatedPostslist(input_data);

        {
         
            if (response.data.status == 'success') {
              
                dispatch({
                    type: 'setrelateddata',
                    payload:response.data.data[0].sectionResult,
                  });
                } else {
                    // alert()
                  dispatch({
                    type: 'setrelateddata',
                    payload: []
                  });
                }
            }; 
     }
    return{
        getRelatedPostslistdata:getRelatedPostslistdata
    }
}
export default RelatedAction;