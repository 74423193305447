import React, { useState ,useEffect} from 'react';
import { LuMenu } from "react-icons/lu";
import { IoClose} from "react-icons/io5";
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
const Sidebar = () => {
    const head = ["news", "earth", "real", "sports", "worklife", ]
    const navdata = useSelector(state => state.masterdata.navdata);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const sidebarStyle = {
        width: '100%',
        height: '100vh',
        backgroundColor: 'black',
        color: '#fff',
        // backgroundColor: '#fff',
        // color: 'black',
        position: 'fixed',
        overflowY: 'auto',
        top: '0',
        zIndex: 10,
        // padding: '10px',
        left: isOpen ? '0' : '-100%',
        transition: 'left 0.3s ease-in-out',
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div style={sidebarStyle}>
                <div className='side-bar-close' onClick={toggleSidebar}>
                   <IoClose/>
                </div>
                <div className='sidebar-main-list'>
                {
                    navdata.map(ele => {
                        return (
                            <NavLink className={"text-decoration-none"} to={`/Category/${ele.slug}`} onClick={toggleSidebar}>
                            <div className='sidebar-content'>
                            { ele.name }
                            </div>
                            </NavLink>
                        )
                    })
                }
       </div>
            </div>
            <div className="content">
                <div onClick={toggleSidebar} ><LuMenu  className='menubar-icon-sidebar'/></div>

            </div>
        </div>
    );
};







export default Sidebar;