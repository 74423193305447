import React, { Suspense, lazy, useEffect, useContext } from 'react';
import {  HomeCarouselMain, HomeTechnologyNews, HomeCardSingleImagewithBlogs, HomeCardSingleBlogs } from '../components/common'
import { UseData } from '../hooks';
import {initainitialState} from '../redux/reducers/MasterReducer'

// import { StateContext } from "../components/context/ManageStates";
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// const HomeCarouselMain = lazy(() => import("../components/common/HomeCarouselMain"));
// const HomeTechnologyNews = lazy(() => import("../components/common/HomeTechnologyNews"));
// const HomeCardSingleBlogs = lazy(() => import("../components/common/HomeCardSingleBlogs"));
const Home = ({homedata}) => {
    // const { homedata } = useContext(initainitialState);
    // const { getNewsDatalistdata } = UseData();
//     const dispatch = useDispatch();
//   const { getNewsDatalistdata } = Homedata()

//   useEffect(()=>{
//     dispatch(getNewsDatalistdata())
//   },[])

   

    return (
        <>
            {
                Object.keys(homedata).length > 0 ?

                //   homedata ?
                < div >
            <HomeCarouselMain homecarousel={homedata.carouselList} />
        
            <HomeTechnologyNews  data1={homedata[1]}  data2={homedata.postcategorydataList}  data3={homedata[3]}  trendingcarousel={homedata.carouselList}/>
           

           
      {
        //   homedata && homedata.postcategorydataList > 0 &&
          <HomeCardSingleBlogs categorydatass={homedata.postcategorydataList} />
      }
             
            
    

            {/* {
                        data2 && data2.items.length > 0 &&
                        <HomeCardSingleImagewithBlogs  data22={data2.items}/>
                    } */}

        </div >:
        <div className='custom-height'></div>
}
</>
    );
};

export default Home;