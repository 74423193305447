import React from 'react';
import { FaUser } from "react-icons/fa";
import { GiTimeBomb } from "react-icons/gi";
const SmallComp = ({ auth_date, className, title ,image,category_name,author_name,discription,published_date}) => {

    
    return (
        <div>
             <div className='smallcomp-main'>

<div className='images-hover'>
    <div className='images-hover-sub-div'>
        <div className='Singleimage-main-image-div small-comp-image-mindiv'>
            <img referrerPolicy="no-referrer" className='Singleimage-image small-comp-image' src={localStorage.getItem("cdn") +image}></img>
            <div className={`imagecard-absolute imagecard-side  Singleimage-main-image-div-images `}>
                {
                    !title &&
                    <div className='ImageCard-category_name small-comp-image-author'><div className='category_name'>{category_name}</div></div>
                }

            </div>

        </div>

    </div>
</div>



<div>
    <div className='small-comp-discription'>{discription}</div>

    {
        auth_date &&
        <div className='Singleimage-maindiv '>
            {/* <div className='Singleimage-author Singleimage-1  '><FaUser className='Singleimage-author-icon' /> <span className='icondata'>{ele.author_name}</span></div> */}


            <div className='Singleimage-author '><GiTimeBomb className='Singleimage-author-icon' /> <span className='icondata'> {published_date}</span> </div>


        </div>
    }

</div>
</div>
           

        </div>




    );
};

export default SmallComp; 