import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageCard from './ImageCard'
import { FaUser } from "react-icons/fa";
import { GiTimeBomb } from "react-icons/gi";
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CategoryAction from '../../redux/actions/CategoryAction';
const Homecarousel = ({ homecarouseldata }) => {
    const mobile = useMediaQuery({ query: '(min-width: 991px)' })
   const {getSubcategoryPostdatalistdata}=CategoryAction();
   const dispatch=useDispatch();
    // const samples = [
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },
    // ]
    const useEffect =()=>{
        
    }
//   const click=(slug)=>{
       
//    const input_data = {
//     slug: slug,
//     page: 1,
//   };
//   dispatch(getSubcategoryPostdatalistdata(input_data));
//   }

    return (
        <div className='daily-deals-main-sidebar'>

            <div className='ourproducts-header-main'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                    spaceBetween={0}
                    slidesPerView={1}

                    effect="fade"
                    loop={true}
                    //   pagination={{ clickable: true }}
                    navigation={{
                        nextEl: ".ImageCard-next ",
                        prevEl: ".ImageCard-prev "
                    }}

                //   autoplay={{
                //     delay: 5000,
                //     disableOnInteraction: false
                //   }}

                >
                    {

                        homecarouseldata.map((ele) => {
                            return (
                                <>
                                    <SwiperSlide className='ImageCard-swiper'>
                                    <NavLink to={`/Detail/${ele.slug}`} className={"text-decoration-none"} >

                                        <ImageCard classname={"carousel_image   "}  dissize={"mainimage"} image={ele.image} discription={ele.title} author_name={ele.author_name} category_name={ele.category_name} published_date={ele.published_date} />
                          </NavLink>
                                        {
                                            // mobile && 
                                            <div >
                                                <div className={` ImageCard-prev  `}><BiSolidChevronLeft size="30" /></div>
                                                <div className={` ImageCard-next `}><BiSolidChevronRight size="30" /></div>
                                            </div>
                                        }
                                    </SwiperSlide>
                                </>
                            )
                        })
                    }

                </Swiper>


            </div>

        </div>
    );
};


export default Homecarousel;