import { DesktopLayout } from '../components/layout';
// import { UseData } from '../hooks';
// import { StateContext } from "../components/context/ManageStates";
import * as ROUTES from '../constants/routes';
import React, { Suspense, lazy ,useEffect,useContext} from 'react';
import { Route, BrowserRouter as Router, Routes, } from 'react-router-dom';
import * as view from '../views';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import Homedata from "../redux/actions/HomeAction"
import { useDispatch } from "react-redux";
import MasterReducer from '../redux/reducers/MasterReducer';
import { ScrollTop } from '../hooks';
 const Home =lazy(()=>import("../../src/views/Home"));
 const Category =lazy(()=>import("../../src/views/Category"));
 const Detail =lazy(()=>import("../../src/views/Detail"));
 
const AppRouter = () => {
  // const homedata = useSelector(state => state.MasterReducer.homedata);
  const homedata = useSelector(state => state.masterdata.homedata);
  const catagorydata = useSelector(state => state.masterdata.cartdata );
  const detaildata = useSelector(state => state.masterdata.deataidata );
  const relateddata = useSelector(state => state.masterdata.relateddata );
//   const mapStateToProps = state => ({
//     homedata: state.homeReducer.homedata,
// });

  // const dispatch = useDispatch();
  // const { getNewsDatalistdata } = Homedata()
  // useEffect(()=>{
  //   // dispatch(getNewsDatalistdata())
  // },[catagorydata])
   
  // const { homedata } = useContext(StateContext);
  // const { getNewsDatalistdata } = UseData();

  // useEffect(() => {
  //     getNewsDatalistdata();
  // }, []);
 
  return (
    <Router >

      <>
<Suspense fallback={<div className='loading-main'>
{/* <img src='https://media0.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=6c09b952q6jqgt6itwupwnvy1w6b4t02ktyb205hn2nue5ap&ep=v1_gifs_search&rid=giphy.gif&ct=g'></img> */}
</div>}>
        <Routes>
          <Route
            element=
            {
              <ScrollTop>

              <DesktopLayout>
                <Home
                 homedata={homedata}
                  />
              </DesktopLayout>
              </ScrollTop>
            }
            exact
            path={ROUTES.HOME}
          />
          <Route
            element={
              <ScrollTop>
              <DesktopLayout>
              <Category />
              
              {/* <Suspense> */}
                {/* <Category 
                category={catagorydata} 
                /> */}
                {/* </Suspense> */}
              </DesktopLayout>
              </ScrollTop>
            }
            path={ROUTES.Category}

          />
          {/* <Route
            element={
              <view.Extract />
            }
            path={"/Extract"}

          /> */}
          <Route
            element={
              <ScrollTop>
              <DesktopLayout>
              {/* <Suspense> */}
              <Detail 
              detaildata={detaildata}
              relateddata={relateddata}
              />
              {/* </Suspense> */}
              </DesktopLayout>
              </ScrollTop>
            }
            path={ROUTES.detail}
            // path={"/detail"}

          />

        </Routes>

        </Suspense>


      </>
    </Router>

  )

};

export default AppRouter;
