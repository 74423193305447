import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageCard from './ImageCard'
import { FaUser } from "react-icons/fa";
import { GiTimeBomb } from "react-icons/gi";
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { useMediaQuery } from 'react-responsive';
import { TitleHeader, Homecarousel, Singleimage } from '../../components/common'
import { NavLink } from 'react-router-dom';
// import { useEffect, useState } from 'react';
const RightCarousel = ({ trendingcarouseldata }) => {
    // const samples = [
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },


    // ]


    return (
        <div>
            <div className='right-carousel-heading'>
                <TitleHeader title={"TRENDING NEWS"} />
                <div className='right-carousel-arrows' >
                    <div className={`RightCarousel-prev `}><BiSolidChevronLeft size="20" /></div>
                    <div className={`RightCarousel-next `}><BiSolidChevronRight size="20" /></div>
                </div>
            </div>
            <div className='ourproducts-header-main'>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]}
                    spaceBetween={0}




                    slidesPerView={1}

                    effect="fade"
                    loop={false}
                    //   pagination={{ clickable: true }}
                    // navigation={true}
                    navigation={{
                        nextEl: ".RightCarousel-next ",
                        prevEl: ".RightCarousel-prev "
                    }}

                //   autoplay={{
                //     delay: 5000,
                //     disableOnInteraction: false
                //   }}

                >
                    {
                      trendingcarouseldata.length > 0 &&
                        trendingcarouseldata.map((ele) => {
                            return (
                                <>
                              
                                    <SwiperSlide className='ImageCard-swiper'>
                                    <NavLink to={`/Detail/${ele.slug}`} className={"text-decoration-none"} >
                                        <ImageCard classname={"carousel_rightimage   right-carousel imagessize"} image={ele.image} discription={ele.title} author_name={ele.author_name} category_name={ele.category_name} published_date={ele.published_date} />
                                        </NavLink>
                                        {/* <Singleimage classname={"carousel_rightimage imagessize"} image={ele.image} discription={ele.discription} author_name={ele.author_name} category_name={ele.category_name} published_date={ele.published_date} /> */}

                                    </SwiperSlide>
                                
                                </>
                            )
                        })
                    }

                </Swiper>


            </div>
        </div>
    );
};

export default RightCarousel;

