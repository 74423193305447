import React, { lazy, Suspense } from 'react';
import { Row, Col } from 'react-bootstrap'
import { ImageCardsingle, TitleHeader, HomeCardSingleImagewithBlogs, HomeRightComp, RightCarousel } from '../../components/common'
// const ImageCardsingle = lazy(() => import("../../components/common/ImageCardsingle"));
// const HomeCardSingleImagewithBlogs = lazy(()=> import("../../components/common/HomeCardSingleImagewithBlogs"));
// const HomeRightComp = lazy(() => import("../../components/common/HomeRightComp"));
// const RightCarousel = lazy(() => import("../../components/common/RightCarousel"));
const HomeTechnologyNews = ({ data1, data2 ,data3,trendingcarousel}) => {
    // let blocks = [];
    // // Check if homecarousel exists and has the 'items' property before accessing it
    // if (data1 && data1.items) {
    //     blocks = data1.items;
    // }
    return (
        <div>
            <Row>
                <Col lg={8} md={12}>
                    {/* {
                        data1 && data1.items.length > 0 &&
                        <ImageCardsingle    data11={data1.items} />
                        
                    } */}
                    
                    { (
                        <HomeCardSingleImagewithBlogs data22={  Object.values(data2).slice(3,4).map((key,index) =>key.slice(0,10))} />
                    )}


                </Col>
                <Col lg={4}>
                {/* {
                        data3 && data3.items.length > 0 &&
                        <HomeRightComp  data33={data3.items}/>
                    } */}
                 {trendingcarousel &&
                        trendingcarousel.remaining_items &&
                        trendingcarousel.remaining_items.length > 0 && (
                            <RightCarousel trendingcarouseldata={trendingcarousel.trending_items} />
                        )}

                    

                </Col>
            </Row>
        </div>
    );
};

export default HomeTechnologyNews;