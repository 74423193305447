import React, { useState } from 'react';
import { FaUser } from "react-icons/fa";
import { IoSearch, IoClose, IoCaretDownSharp } from "react-icons/io5";
import { LuMenu } from "react-icons/lu";
import { GoSquareFill } from "react-icons/go";
import { useMediaQuery } from 'react-responsive';
import { Sidebar } from '../../components/common'
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import imagelogo from '../../images/RT_logo.png';
import { useDispatch } from 'react-redux';
const Header = () => {
    const head = ["home", "news", "earth", "real", "sports", "worklife",]
    const dispatch = useDispatch();
    const navdata = useSelector(state => state.masterdata.navdata);
    const [selectedOption, setSelectedOption] = useState(null);
    const langdata = useSelector(state => state.masterdata.langdata);
    const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
    const keysdata = langdata  && langdata[0]
        ? Object.keys(langdata[0])
        : [];
    const [data, setData] = useState(false);
    const ismobile = useMediaQuery({ query: '(min-width: 520px)' })
    const Handle = () => {
        setData(!data)
    }
    const [dataindex, setDataindex] = useState(0);
    const Clicks = (id) => {
        setDataindex(id)
        
    }
    const clicked = (data) => {
        // alert(data)
        dispatch({
            type: 'setslectedkeys',
            payload: data

        });
        setSelectedOption(data);
    }


    return (
        <div className='main-header'>
            <div className='Header'>
                
                    <div className='header-logo'>

                    <NavLink  className={"text-decoration-none"}  to="/">
                        <img referrerPolicy="no-referrer" className='header-logo-image' src={localStorage.getItem('company_logo')}></img>
                   </NavLink>
                   
                    </div>
          
                <div>
                   





                </div>
               
            </div>
           


            <div className='header-below'>
                {/* <div className='header-below-title'>NEWS</div> */}
                {
                    ismobile ?
                        <div className='header-below-list'>
                              {/* <NavLink  className={"text-decoration-none"}  to="/">
                                <span
                                    className={`header-below-list-items  ${dataindex === 0 ? 'active' : ''
                                        }`}
                                    onClick={() => {
                                        Clicks(0);
                                    }}
                                >
                                    <span className='ftdfdf'></span>
                                   
                                    {/* {slectedkeys != '' && (langdata.length > 0) ? (
                                        langdata
                                            .filter(comp => comp.KEYS === "header_navlink_name1")
                                            .map(translatedData => (
                                    
                                                        <span key={translatedData.id}>
                                                            {translatedData[slectedkeys]}
                                                        </span>
                                                       
                                            ))
                                    ) : "Home"

                    } */}
                                {/* </span> */}
                                {/* </NavLink> */} 
                            
                            <div className='dgfdh'>

                               



                                {navdata.map((ele, index) => (

                                    <div key={ele.id}>
                                        <NavLink className={"text-decoration-none"} to={`/Category/${ele.slug}`}>
                                            <span
                                                className={`header-below-list-items ${dataindex === ele.id ? 'active' : 'disable'
                                                    }`}
                                                onClick={() => {
                                                    Clicks(ele.id);
                                                }}
                                            >
                                                <span className='ftdfdf'></span> {ele.name}
                                            </span>
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                            {/* <span className='header-below-list-items header-below-list-more'> more <IoCaretDownSharp  /></span> */}
                        </div>
                        : <div ><Sidebar /></div>
                }

            </div>

        </div >
    );
};

export default Header;