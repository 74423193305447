import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ImageCard, TitleHeader, SmallComp, HomeBig_small_blogs } from '../../components/common'

const HomeCardSingleImagewithBlogs = ({ data22 }) => {
    // const firstArray = relateddata && relateddata.length > 0 ? relateddata.slice(0, 5) : [];
    const latestnewsfirst =data22 && data22.length >0 && data22[0].slice(0,5);
    const latestnewssecond =data22 && data22.length >0 && data22[0].slice(5,10);
    // const datasmall = [
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },



    // ]
    // const datasmall1 = [
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
    //         category_name: "travel",
    //         author_name: "murali",
    //         discription: "Early tourists choices to the sea of Maldiv…",
    //         published_date: "20/20/2039",
    //     },
    //     {
    //         image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
    //         category_name: "health",
    //         author_name: "Sridhar",
    //         discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
    //         published_date: "20/20/2039",
    //     },




    // ]

    return (
        <div>
            <div><TitleHeader title={latestnewsfirst[0].category_name + " NEWS"} /></div>
            <Row>
                
               <Col lg={6} md={12} sm={12}>
               <HomeBig_small_blogs categoryreleteddata={latestnewsfirst} />
                </Col>
                  <Col lg={6} md={12} sm={12}>
                  <HomeBig_small_blogs categoryreleteddata={latestnewssecond} />
                  </Col>
                
                {/* {
                    data22 && data22.length > 0 &&
                    data22.map(ele => {
                        return (
                            <Col lg={6} md={12} sm={12}>
                                <HomeBig_small_blogs datasmall={ele} />
                            </Col>
                        )
                    })
                } */}

                {/* <Col lg={6} md={12} sm={12}>
                <HomeBig_small_blogs    datasmall={datasmall1}/>
                </Col> */}
            </Row>
        </div>
    );
};

export default HomeCardSingleImagewithBlogs;