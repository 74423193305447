import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Homecarousel, ImageCard } from '../../components/common';
// const Homecarousel = lazy(() => import("../../components/common/Homecarousel"));
// const ImageCard = lazy(() => import("../../components/common/ImageCard"));
// import Homecarousel = lazy(()=>import("../../components/common/Homecarousel"));
const HomeCarouselMain = ({ homecarousel }) => {

    // let carouselItems = [];
    // // Check if homecarousel exists and has the 'items' property before accessing it
    // if (homecarousel && homecarousel.items) {
    //     carouselItems = homecarousel.items;
    // }
    // let homecarouselremaing = {}
    // // Check if homecarousel exists and has the 'items' property before accessing it
    // if (homecarousel && homecarousel.remaining_items[0]) {
    //     homecarouselremaing = homecarousel.remaining_items[0];
    // }
    // useEffect (()=>{
    //     // getNewsDatalistdata();
    //     // carouselItems = homecarousel.items;
    // },[homecarousel]);
    // const [homeright,setHomeright]=useState({homecarousel.remaining_items[0]})
    // useEffect(()=>{
    //     setHomeright(homecarousel.remaining_items[0])
    // },[]);

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col lg={7} md={12} className=' home-maingghg'>
                   
                            {
                                homecarousel && homecarousel.items.length > 0 &&
                                <Homecarousel homecarouseldata={homecarousel.items} />
                            }







                    

                    </Col>
                    <Col lg={5} md={12} className='home-col'>
                        <Row className='imagecard-side1 m-0 p-0'>
                            <Col lg={12} md={12} className='home-col1'>
                                {
                                    homecarousel && homecarousel.remaining_items.length > 0 &&
                                    <NavLink to={`/Detail/${homecarousel.remaining_items[0].slug}`} className={"text-decoration-none"} >
                                    <ImageCard classname={"carousel_rightimage"} image={homecarousel.remaining_items[0].image} author_name={homecarousel.remaining_items[0].author_name} category_name={homecarousel.remaining_items[0].category_name} published_date={homecarousel.remaining_items[0].published_date} discription={homecarousel.remaining_items[0].title} />
                                 </NavLink>
                                }



                            </Col>


                            <Col lg={6} md={12} className='home-col2'>
                                {
                                    homecarousel && homecarousel.remaining_items.length > 0 &&
                                    <NavLink to={`/Detail/${homecarousel.remaining_items[1].slug}`} className={"text-decoration-none"} >
                                    <ImageCard classname={"carousel_bottomimage"} image={homecarousel.remaining_items[1].image} author_name={homecarousel.remaining_items[1].author_name} category_name={homecarousel.remaining_items[1].category_name} published_date={homecarousel.remaining_items[1].published_date} discription={homecarousel.remaining_items[1].title}/>
                                    </NavLink>
                                }


                            </Col>
                            <Col lg={6} md={12} className='home-col2'>
                                {
                                    homecarousel && homecarousel.remaining_items.length > 0 &&
                                    <NavLink to={`/Detail/${homecarousel.remaining_items[2].slug}`} className={"text-decoration-none"} >
                                    <ImageCard classname={"carousel_bottomimage"} image={homecarousel.remaining_items[2].image} author_name={homecarousel.remaining_items[2].author_name} category_name={homecarousel.remaining_items[2].category_name} published_date={homecarousel.remaining_items[2].published_date} discription={homecarousel.remaining_items[2].title}/>
                                    </NavLink>
                                }


                            </Col>
                        </Row>

                    </Col>

                </Row>

            </Container>
        </div>
    );
};

export default HomeCarouselMain;