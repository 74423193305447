const initialState = {
  loaderstatus: false,
  homedata:{},
  cartdata:{},
  cartdisplay:[],
  deataidata:[],
  relateddata:[],
  populardata:[],
  navdata:[],
  hasmore:true,
  langdata:[],
  slectedkeys:'',
  page:1,
  vendor_token:'',
  categoryslug:'',
  subcategoryslug:'',
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'setloader':
      return {
        ...state,
        loaderstatus: action.payload
      };
    case 'setcartdisplay':
      return {
        ...state,
        cartdisplay: action.payload
      };
      case 'setcatgoryslug':
        return {
          ...state,
          categoryslug: action.payload
        };
        case 'setsubcategoryslug':
          return {
            ...state,
            subcategoryslug: action.payload
          };
    case 'setHasmore':
      return {
        ...state,
        hasmore: action.payload
      };
    case 'setslectedkeys':
      return {
        ...state,
        slectedkeys: action.payload
      };
      case 'setvendor_token':
        return {
          ...state,
          vendor_token: action.payload
        };
      case 'setpage':
        return {
          ...state,
          page: action.payload
        };
    case 'setlangdata':
      return {
        ...state,
        langdata: action.payload
      };
    case 'setHomedata':
      return {
        ...state,
        homedata: action.payload
      };
      case 'setnavdata':
        return {
          ...state,
          navdata: action.payload
        };
      case 'setpopulardata':
        return {
          ...state,
          populardata: action.payload
        };
    case "setCartdata":
      return{
        ...state,
        cartdata: action.payload
      };
      case "satDeataidata":
        return{
          ...state,
          deataidata: action.payload
        }
        case "setrelateddata":
          return{
            ...state,
            relateddata:action.payload
          };

    default:
      return state;
  }
};



// const initialState = {
//   loading: false,
//   authStatus: false,
//   userData: {},
//   HomePageData: [],
//   categoryData: [],
//   sidebar: false,
//   masterData: {},
//   error: null,
//   categoryOpen: 'level1',
//   presntcategoryOpen: "",
//   presntsubcategoryOpen: ""
// };

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case 'homepage_detail':
//       return {
//         ...state,
//         loading: false,
//         HomePageData: action.payload
//       };
//     case 'masterData':
//       return {
//         ...state,
//         loading: false,
//         masterData: action.payload
//       };
//     case 'categorylistData':
//       return {
//         ...state,
//         loading: false,
//         categoryData: action.payload
//       };
//     case 'setsidebar':
//       return {
//         ...state,
//         sidebar: action.payload
//       };
//     case 'setcategoryOpen':
//       return {
//         ...state,
//         categoryOpen: action.payload
//       };
//     case 'setauthStatus':
//       return {
//         ...state,
//         authStatus: action.payload
//       };
//     case 'setpresentcategoryOpen':
//       return {
//         ...state,
//         presntcategoryOpen: action.payload
//       };
//     case 'setpresentsubcategoryOpen':
//       return {
//         ...state,
//         presntsubcategoryOpen: action.payload
//       };
//     default:
//       return state;
//   }
// };
