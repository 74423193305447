import React, { useContext, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
const Loder = () => {

    const loaderstatus = useSelector(state => state.masterdata.loaderstatus);


    useEffect(() => {
        if (loaderstatus == true) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

    }, [loaderstatus])
    return (
        <>
            {
                loaderstatus &&
                <div className="preloader">
                    <div>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            {/* <ClipLoader className="cart-btn-loader" loading={true} size={50} /> */}
                            <Spinner animation="border" role="status" className='custom-spinner' />
                        </div>
                    </div>

                </div>
            }
        </>


    );
};

export default Loder;