import React, { useContext, useEffect } from "react";
import AppRouter from "./routers/AppRouter";
// import MasterReducer from "./redux/reducers/MasterReducer";
import Homedata from "./redux/actions/HomeAction"
import CategoryAction from "./redux/actions/CategoryAction";
import MasterAction from "./redux/actions/MasterAction";
import DetailAction from "./redux/actions/DetailAction";
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
// import xlfile from './images/translanguage.xlsx'
import * as XLSX from 'xlsx';

const App = () => {

  const { slug } = useParams();
  const dispatch = useDispatch();
  const { getNewsDatalistdata,getNavigationlistdata } = Homedata()
  const{ getMasterData ,getVendorToken } = MasterAction()


  //  const { getdetaillistdata } = DetailAction()
  useEffect(() => {
    dispatch(getNewsDatalistdata());
    dispatch(getNavigationlistdata());
    dispatch(getMasterData());

  }, [ getNewsDatalistdata,getNavigationlistdata,getMasterData]);
  // alert()

  // useEffect(() => {
  //   if (localStorage.getItem("qwerty") === null) {
  //     dispatch(getVendorToken())
  //   } else {
  //     dispatch({ type: 'setvendor_token', payload: localStorage.getItem("qwerty") })
  //   }
  // }, [])
  // useEffect(() => {
  //   const fetchExcelFile = async () => {
  //     try {
  //       const response = await fetch('./images/translanguage.xlsx');
  //       const blob = await response.blob();

  //       const reader = new FileReader();

  //       reader.onload = (e) => {
  //         const data = new Uint8Array(e.target.result);
  //         const workbook = XLSX.read(data, { type: 'array' });

  //         const jsonData = convertToJSON(workbook);
  //         dispatch({
  //           type: 'setlangdata',
  //           payload: jsonData.Web_Translate
  //         });
  //       };

  //       reader.readAsArrayBuffer(blob);
  //     } catch (error) {
  //     }
  //   };

  //   fetchExcelFile();

  //   const convertToJSON = (workbook) => {
  //     const result = {};

  //     workbook.SheetNames.forEach((sheetName) => {
  //       const sheet = workbook.Sheets[sheetName];
  //       result[sheetName] = XLSX.utils.sheet_to_json(sheet);
  //     });

  //     return result;
  //   };
  // }, []);
  return (

    <AppRouter />

  );
};

export default App;
