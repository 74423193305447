import React, { lazy, Suspense } from 'react';
import { ImageCard, TitleHeader, SmallComp, HomeBig_small_blogs } from '../../components/common'
import { Row, Col } from 'react-bootstrap';
// const HomeBig_small_blogs = lazy(() => import("../../components/common/HomeBig_small_blogs"))
const HomeCardSingleBlogs = ({ className, categorydatass, relateddata }) => {


    const firstArray = relateddata && relateddata.length > 0 ? relateddata.slice(0, 5) : [];
    const secondArray = relateddata && relateddata.length > 0 ? relateddata.slice(5, 10) : [];
    const thirdArray = relateddata && relateddata.length > 0 ? relateddata.slice(10, 15) : [];
    const businessarray = categorydatass ? Object.values(categorydatass)[0].slice(0, 5) : [];
    const Galleryarray = categorydatass ? Object.values(categorydatass)[1].slice(0, 5) : [];
    const Moviearray = categorydatass ? Object.values(categorydatass)[2].slice(0, 5) : [];
    const datasmall = [
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
            category_name: "health",
            author_name: "Sridhar",
            discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
            category_name: "travel",
            author_name: "murali",
            discription: "Early tourists choices to the sea of Maldiv…",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/travel5.jpg",
            category_name: "health",
            author_name: "Sridhar",
            discription: "Why The iPhone X Will Force Apple To Choose Between Good Or Evil",
            published_date: "20/20/2039",
        },
        {
            image: "https://utouchdesign.com/themes/envato/altroznews/images/news/lifestyle/health5.jpg",
            category_name: "travel",
            author_name: "murali",
            discription: "Early tourists choices to the sea of Maldiv…",
            published_date: "20/20/2039",
        },




    ]
    return (
        <div>
            <Row>
                {
                    relateddata &&
                    <>
                        <Row>
                            <Col lg={4} md={12} sm={12}>
                                <HomeBig_small_blogs arrayrelated={firstArray} />

                            </Col>
                            <Col lg={4} md={12} sm={12}>
                                <HomeBig_small_blogs arrayrelated={secondArray} />
                            </Col>
                            <Col lg={4} md={12} sm={12}>
                                <HomeBig_small_blogs arrayrelated={thirdArray} />
                            </Col>
                        </Row>
                    </>
                }
                {
                    categorydatass &&
                    <>
                        <Row>
                            {
                                categorydatass && Object.values(categorydatass).slice(0,3).map((key,index) => (
                                  
                                    <Col lg={4} md={12} sm={12} key={index}>
                                        
                                       <div>
                                   
                                    {
                                        <TitleHeader title={key[0].category_name + " News"} />
                                    }
                                </div>

                                <HomeBig_small_blogs categoryreleteddata={key.slice(0,5)} />
                                    </Col>
                                ))
                            }
                         
                        </Row>
                    </>
                }


                {/* {
                    relateddata && relateddata.length > 0 &&
                    relateddata.slice(0, 5).map(ele => {
                        return (

                            <Col lg={4} md={12} sm={12}>
                                {
                                    <div><TitleHeader title={"related posts"} /></div>
                                }
                                <HomeBig_small_blogs datasmall={ele} />
                            </Col>
                        )
                    })
                } */}

                {/* {
                    data5 && data5.length > 0 &&
                    data5.map(ele => {
                        return (
                            <Col lg={4} md={12} sm={12}>
                                {
                                    !className &&
                                    <div><TitleHeader title={"TRAVEL NEWS"} /></div>
                                }
                                <HomeBig_small_blogs datasmall={ele} />
                            </Col>
                        )
                    })
                } */}








            </Row>
        </div>
    );
};

export default HomeCardSingleBlogs;