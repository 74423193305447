import React from 'react';
import { FaUser } from "react-icons/fa";
import { GiTimeBomb } from "react-icons/gi";
const Singleimage = ({ image, discription, author_name, category_name, published_date, content ,head}) => {
    return (
        <div>
            <div className='images-hover'>
                <div className='images-hover-sub-div'>
                    <div className='Singleimage-main-image-div'>
                        <img referrerPolicy="no-referrer" className='Singleimage-image' src={localStorage.getItem("cdn") + image}></img>
                        <div className={`imagecard-absolute imagecard-side  Singleimage-main-image-div-images `}>
{
    category_name &&
    <div className='ImageCard-category_name small-comp-image-author'><div className='category_name'>{category_name}</div></div>
}
                          
                        </div>
                    </div>

                </div>
            </div>
            <h5 className={`Singleimage-image-discription  ${head}` }>
                {discription}
            </h5>
            {
                author_name && published_date &&
                <div className='Singleimage-maindiv '>


                    {/* <div className='Singleimage-author Singleimage-1  '><FaUser className='Singleimage-author-icon' /> {author_name}</div> */}


                    <div className='Singleimage-author '><GiTimeBomb className='Singleimage-author-icon' /> {published_date} </div>


                </div>
            }

            <div className='Singleimage-image-large-discription'>
            {/* <div dangerouslySetInnerHTML={{ __html:      content}} /> */}
              
            </div>
        </div>

    );
};

export default Singleimage;