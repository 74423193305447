import React from 'react';
import Apis from "../../services/Apis";

const MasterAction = () => {

  const { HomepageComponent, categoryListService, getMasterDataService,gvt } = Apis()

  const getHomepageData = () => async dispatch => {

    var input_data = {
      "page_key": "home"
    }
    const response = await HomepageComponent(input_data);
    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'homepage_detail',
          payload: [response.data]
        });
      } else {
        dispatch({
          type: 'homepage_detail',
          payload: []
        });
      }
    }
  };

  const getMasterData = () => async dispatch => {
    const response = await getMasterDataService();

    if (response.status == 200) {
      if (response.data.status == 'success') {
        if (localStorage.getItem("cdn") === null || localStorage.getItem("cdn") != response.data.data.vendor.cdn) {
          localStorage.setItem('cdn', response.data.data.vendor.cdn_domain);
        }
        if (localStorage.getItem("footer_logo") === null || localStorage.getItem("footer_logo") != response.data.data.vendor.footer_logo) {
          localStorage.setItem('footer_logo', response.data.data.vendor.footer_logo);
        }
        if (localStorage.getItem("vendor_contact") === null || localStorage.getItem("vendor_contact") != response.data.data.vendor.vendor_contact) {
          localStorage.setItem('vendor_contact', response.data.data.vendor.vendor_contact);
        }
        if (localStorage.getItem("vendor_email") === null || localStorage.getItem("vendor_email") != response.data.data.vendor.vendor_email) {
          localStorage.setItem('vendor_email', response.data.data.vendor.vendor_email);
        }
        if (localStorage.getItem("address") === null || localStorage.getItem("address") != response.data.data.vendor.address) {
          localStorage.setItem('address', response.data.data.vendor.address);
        }
        if (localStorage.getItem("favicon") === null || localStorage.getItem("favicon") != response.data.data.vendor.favicon) {
          localStorage.setItem('favicon', response.data.data.vendor.favicon);
        }
        if (localStorage.getItem("title") === null || localStorage.getItem("title") != response.data.data.vendor.title) {
          localStorage.setItem('title', response.data.data.vendor.agency_name);
        }
        if (localStorage.getItem("company_logo") === null || localStorage.getItem("company_logo") != response.data.data.vendor.company_logo) {
          localStorage.setItem('company_logo', response.data.data.vendor.company_logo);
        }
        dispatch({
          type: 'masterData',
          payload: response.data.data
        });
      } else {
        dispatch({
          type: 'masterData',
          payload: []
        });
      }
    }
  };



  const getVendorToken = () => async dispatch => {
    // dispatch({
    //   type: 'setloader',
    //   payload: true
    // });

    const response = await gvt();

    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'setvendor_token',
          payload: response.data.vt
        });
        // dispatch({
        //   type: 'setloader',
        //   payload: false
        // });
        localStorage.setItem('qwerty', response.data.vt);
      }
    }
  };




  const getcategorylistData = () => async dispatch => {
    const response = await categoryListService();

    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'categorylistData',
          payload: response.data.categoryList
        });
      } else {
        dispatch({
          type: 'categorylistData',
          payload: []
        });
      }
    }
  };

  return {
    getHomepageData: getHomepageData,
    getMasterData: getMasterData,
    getcategorylistData: getcategorylistData,
    getVendorToken:getVendorToken
  }
};

export default MasterAction;