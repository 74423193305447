import React from 'react';
// import { GoDotFill } from "react-icons/go";
const TitleHeader = ({ title }) => {
    return (
        <h3 className='header-main-div'>
            <span className='header-heading'>{title}</span>
        </h3>
    );
};

export default TitleHeader;